@use "../../import" as *;

.p-error {
  &__mv-area {
    max-width: 496px;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }

  &__mv {
    width: 100%;
    height: 100%;
  }

  &__text-area {
    margin-top: 45px;
    text-align: center;
    @include mq(sp) {
      margin-top: 20px;
    }
  }

  &__text {
    @include font(24,40);
    font-weight: bold;
    color: $colorFont;
    @include mq(sp) {
      @include font(18,32);
    }
  }

  &__btn-area {
    margin-top: 45px;
    display: flex;
    justify-content: center;
    @include mq(sp) {
      margin-top: 20px;
    }
  }
}