@use "../../import" as *;

.l-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: $colorWhite;
  z-index: 10;
  @include mq(sp) {
    @include innerWidth(375);
    padding-left: 20px;
    padding-right: 20px;
  }
}

.l-header__inner {
  @include innerWidth(950);
  margin-top: 27px;
  background-color: $colorWhite;
  @include mq(sp) {
    margin-top: 0;
  }
}

.l-main {
  width: 100%;
}

.l-inner {
  @include innerWidth(950);
}

.l-top {
  padding-top: 57px;
  padding-bottom: 124px;
  @include mq(sp) {
    padding-top: 39px;
    padding-bottom: 22px;
  }
}

.l-select {
  padding-top: 165px;
  @include mq(sp) {
    padding: 81.5px 20px 0;
    @include innerWidth(375);
  }
}

.l-format {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100svh - 105px);
  height: 100%;
  padding-top: 105px;

  .p-btn__wrapper-vertical {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  @include mq(sp) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.l-answer {
  padding-top: 157px;
  height: calc(100dvh - 236px);
  overflow-y: scroll;
  @include mq(sp) {
    padding: 81.5px 20px 0;
    @include innerWidth(375);
    height: calc(100dvh - 170px);
  }
}

.l-result {
  padding-top: 105px;
  padding-bottom: 40px;
  @include mq(sp) {
    padding: 52px 0px;
  }
}

.l-error {
  @include innerWidth(496);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  @include mq(sp) {
    padding: 0 39px;
  }
}

.l-modal {
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: none;
  z-index: 30;
}

.l-modal-preview {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: none;
  z-index: 30;
}

.l-overlay {
  display: none;
  width: 100vh;
  height: 100vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 20;
}

.l-overlay--gray {
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 20;
  background-color: #6F6F6F;
  opacity: 0.9;
}

.is-open--back {
  .l-modal.l-modal--back {
    display: block;
  }

  .l-overlay {
    display: block;
  }
}

.is-open--answer {
  .l-modal.l-modal--answer {
    display: block;
  }

  .l-overlay {
    display: block;
  }
}

.is-open--preview {
  .l-modal-preview {
    display: block;
  }

  .l-overlay--gray {
    display: block;
  }
}