@use "../../import" as *;

.p-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 15px 45px;
  color: $colorFont;
  @include mq(sp) {
    padding: 10px 30px;
  }

  &__btn-area {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 44px;
    height: 44px;
    @include mq(sp) {
      width: 28px;
      height: 28px;
    }
  }

  &__btn {
    position: relative;
    width: 100%;
    height: 100%;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: contain;
      @include mq(sp) {
        width: 28px;
        height: 28px;
      }
    }

    &-back::before {
      background-image: url("../img/icon_back.svg");
    }

    &-close::before {
      background-image: url("../img/icon_close.svg");
    } 
  }

  &__text {
    @include font(32,48);
    font-weight: bold;
    @include mq(sp) {
      @include font(18,32);
    }
  }
}