@use "../../import" as *;

.c-heading {
  &__big {
    @include font(32,48);
    font-weight: bold;
    padding-left: 21px;
    position: relative;
    color: $colorFont;

    @include mq(sp) {
      @include font(18,32);
    }

    &::before {
      content: "";
      display: block;
      width: 10px;
      height: calc(100% - 12px);
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background-color: $colorMain;

      @include mq(sp) {
        width: 6px;
      }
    }
  }

  &__middle {
    @include font(24,42);
    font-weight: bold;
    margin-top: 60px;
    margin-bottom: 32px;
    padding-left: 21px;
    position: relative;
    color: $colorFont;
    @include mq(sp) {
      margin-top: 29.5px;
      margin-bottom: 16px;
      @include font(16,28);
    }

    &::before {
      content: "";
      display: block;
      width: 9px;
      height: calc(100% - 1px);
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background-color: $colorMain;
      @include mq(sp) {
        width: 6px;
      }
    }
  }

  &__wrapper {
    margin-left: 152px;
    @include mq(sp) {
      margin-left: 0;
    }
  }
}