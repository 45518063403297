@use "../../import" as *;

.p-btn {
  &__wrapper-vertical {
    @include innerWidth(950);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .c-btn__icon {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__foot {
    margin-top: 40px;
    padding-bottom: 75px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    background-color: #fff;
    @include mq(sp) {
      padding-right: 20px;
      padding-left: 20px;
    padding-bottom: 28px;
      @include innerWidth(375);
    }
    .c-btn__answer {
      margin-right: 16px;
      flex-grow: 0;
      flex-shrink: 1;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}