@use "../../import" as *;

.p-sitemap {
  @include innerWidth(800);

  &__item {
    &:first-child {
      border-top: 1px solid $colorMain;
    }
  }

  thead &__head,
  thead &__data {
    font-weight: bold;
  }

  &__head {
    padding: 16px 8px;
    background-color: $colorMain;
    @include font(16,24);
    color: $colorWhite;
    text-align: center;
    border-bottom: 1px solid $colorWhite;
    
  }

  &__data {
    padding: 16px 8px;
    background-color: $colorWhite;
    @include font(16,24);
    color: $colorFont;
    text-align: center;
    border-bottom: 1px solid $colorMain;
    border-right: 1px solid $colorMain;

    a {
      color: $colorMain;
      text-decoration: underline;
    }
  }
}