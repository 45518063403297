@use "../../import" as *;

.p-chapter {
  width: fit-content;
  margin: 0 auto;
  font-family: 'Lexend';
  @include mq(sp) {
    margin: 0;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mq(sp) {
      justify-content: flex-start;
    }
  }

  &__item {
    margin-right: 89px;
    @include mq(sp) {
      margin-right: 12px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__btn {
    width: 100px;
    height: 85px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8.25px 20px;
    border-radius: 16px;
    border: 3px solid $colorMain;
    background-color: $colorWhite;
    color: $colorMain;
    @include mq(sp) {
      width: 66px;
      height: 57px;
      padding: 5.5px 13px;
      border-width: 2px;
    }

    &.is-current {
      background-color: $colorMain; 
      color: $colorWhite;
    }
  }

  &__btn-number {
    @include font(36,69);
    font-weight: bold;
    @include mq(sp) {
      @include font(24,46);
    }
  }

  &__btn-text {
    @include font(30,69);
    font-weight: bold;
    font-family: 'Lexend','Noto sans jp';
    @include mq(sp) {
      @include font(20,46);
    }
  }

  &__description {
    @include font(18,32);
    font-weight: bold;
    color: $colorFont;
    text-align: center;
    @include mq(sp) {
      @include font(16,28);
      text-align: left;
    }
  }
}