@use "../import" as *;

img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

body {
  @include font(16,24);
  font-family: 'Noto Sans JP' ,"Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", 'Inter' ,sans-serif;
  font-weight: normal;
  color: #000;
}

.is-none {
  display: none;
}

.pc-none {
  display: none;
  @include mq(sp) {
    display: block;
  }
}

.sp-none {
  @include mq(sp) {
    display: none;
  }
}


.is-open--back {
  .p-header,
  .p-bar,
  .p-answer,
  .c-btn__answer input  {
    opacity: 0.2;
  }
}

.is-open--answer {
  .p-header,
  .p-bar,
  .p-answer,
  .c-btn__answer input {
    opacity: 0.2;
  }
  
  .c-btn__answer input:checked {
    opacity: 1;
  }
}

:root {
  --width-current: 0;
}