@use "../../import" as *;

.p-answer {
  margin-top: 40px;
  
  @include mq(sp) {
    margin-top: 16px;
  }

  &__text-area {
    @include innerWidth(585);
  }

  &__question {
    padding: 10.5px 22px;
    @include font(24,33);
    font-weight: bold;
    color: $colorFont;
    text-align: center;
    @include mq(sp) {
      padding: 10px 21px;
      @include font(18,32);
      text-align: left;
    }
  }

  &__text {
    margin-top: 20px;
    padding: 10.5px 22px;
    @include font(18,32);
    color: $colorFont;
    @include mq(sp) {
      margin-top: 0;
      padding: 10px 21px;
    }
  }

  &__img-area {
    margin-top: 50.5px;
    background-color: #6F6F6F;
    @include innerWidth(463);
    height: auto;
    padding: 20px;
    position: relative;
    @include mq(sp) {
      margin-top: 16px;
      padding: 13px 15px;
      @include innerWidth(308);
    }
  }

  &__scale-btn {
    max-width: 57px;
    width: 100%;
    height: 30px;
    border: 2px solid $colorMain;    
    background-color: $colorWhite;
    position: absolute;
    left: 20px;
    bottom: 11px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mq(sp) {
      max-width: 38px;
      height: 20px;
      border: 1px solid $colorMain; 
      left: 10px;
      bottom: 8px;
    }

    &::before {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url("../img/icon_scale.svg");
      @include mq(sp) {
        width: 15px;
        height: 15px;
      }
    }

    &:hover {
      background-color: $colorHover;
    }
  }
}