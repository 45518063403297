@use "../../import" as *;

.p-accordion {
  
  &-select {
    width: 100%;

    &__heading-item {
      margin-bottom: 36.5px;
      counter-increment: heading;
      @include mq(sp) {
        margin-bottom: 30px;
      }
    }

    &__heading {
      margin-left: -23px;
      padding: 0 160px 20px;

      @include mq(sp) {
        padding: 0 0 16px 0;
        margin-left: -8px;
      }

      &-text {
        @include font(18,32);
        position: relative;
        padding-left: 60px;
        @include mq(sp) {
          @include font(16,28);
          padding-left: 41px;
        }

        &::before {
          content: "（"counter(heading)"）";
          display: inline-block;
          width: 60px;
          position: absolute;
          left: 0;
          @include mq(sp) {
            width: 41px;
          }
        }
      }
    }

    &__category-item {
      counter-increment: category;
    }

    &__category-item:last-child &__category-heading {
      border-bottom: none;
    }

    &__category-item.is-open:last-child &__category-heading {
      border-bottom: 2px solid rgba(133, 64, 0, .2);
    }

    &__category-item.is-open:last-child &__item:last-child {
      border-bottom: 1px solid #DDDDDD;
    }

    &__category-heading {
      background-color: $colorThin;
      padding: 19px 90px 19px 160px;
      border-bottom: 2px solid rgba(133, 64, 0, .2);
      position: relative;
      cursor: pointer;      
      @include mq(sp) {
        padding: 24px 12px 24px 10px;
      }
    }

    &__category-text {
      position: relative;
      @include font(18,32);
      padding-right: 70px;
      padding-left: 40px;
      @include mq(sp) {
        @include font(16,20);
        padding-right: 40px;
      }
      &::before {
        content: counter(category,upper-roman)".";
        display: inline-block;
        width: 40px;
        position: absolute;
        left: 0;
        top: 0;
      }

      &::after {
        content: "";
        display: block;
        width: 28px;
        height: 28px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        background-image: url("../img/icon_arrow.svg");
        background-position: center;
        background-size: contain;
        transition: 0.3s;
        @include mq(sp) {
          width: 20px;
          height: 20px;
          top: 50%;
          right: 0;
        }
      }
    }

    &__list {
      height: 0;
      padding: 0;
      overflow: hidden;
      transition: .5s;
    }

    &__item {
      padding: 19px 160px;
      border-bottom: 1px solid #DDDDDD;
      counter-increment: number;

      @include mq(sp) {
        padding: 21px 10px;
        @include font(16,28);
      }

      &:last-child {
        border-bottom: none;
      }
    }

    &__item-text {
      @include font(18,32);
      position: relative;
      padding-left: 40px;
      &::before {
        content: counter(number);
        display: inline-block;
        width: 40px;
        position: absolute;
        top: 0;
        left: 0;
      }

      @include mq(sp) {
        @include font(16,28);
      }
    }

    &__category-item.is-open &__list {
      display: block;
    }

    &__category-item.is-open &__category-text {
      &::after {
        transition: 0.3s;
        transform: translateY(-50%);
      }
    }
  }

  &-chapter {
    margin-top: 40px;
    @include mq(sp) {
      margin-top: 23px;
    }

    &__category-item:last-child &__category-heading {
      border-bottom: none;
    }

    &__category-item.is-open:last-child &__category-heading {
      border-bottom: 2px solid rgba(133, 64, 0, .2);
    }

    &__category-item.is-open:last-child &__item {
      border-bottom: 1px solid #DDDDDD;
    }

    &__category-heading {
      background-color: $colorThin;
      padding: 16px 155px;
      @include font(18,32);
      border-bottom: 2px solid rgba(133, 64, 0, .2);
      position: relative;
      cursor: pointer;
      @include mq(sp) {
        @include font(16,28);
        padding: 8px 12px 8px 9px;
      }

      &::after {
        content: "";
        display: block;
        width: 28px;
        height: 28px;
        position: absolute;
        right: 90px;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        background-image: url("../img/icon_arrow.svg");
        background-position: center;
        background-size: contain;
        transition: 0.3s;
        @include mq(sp) {
          width: 20px;
          height: 20px;
          right: 12px;
        }
      }
    }

    &__heading-area {
      @include mq(sp) {
        padding-right: 30px;
      }
    }

    &__list {
      height: 0;
      padding: 0;
      overflow: hidden;
      transition: .5s;
    }

    &__item {
      padding: 16px 155px;
      @include font(18,32);
      border-bottom: 1px solid #DDDDDD;
      &:last-child {
        border-bottom: none;
      }
      @include mq(sp) {
        padding: 21px 14px;
        @include font(16,28);
      }
    }

    &__item-number {
      font-family: Inter;
      padding-left: 0.25em;
    }

    &__heading-top {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
    }

    &__heading-bottom {
      padding-left: 7px;
    }

    &__badge {
      flex-shrink: 1;
      flex-grow: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 35px;
      border-radius: 8px;
      background-color: #FACCA2;
      text-align: center;
      @include font(18,32);
      color: $colorFont;
      margin-right: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include mq(sp) {
        padding: 3.5px 8.5px;
        @include font(14,14);
        width: 34px;
        height: 24px;
        color: #000;
        margin-right: 4px;
      }
    } 

    &__page {
      color: $colorFont;
      @include font(18,32);

      @include mq(sp) {
        @include font(16,28);
      }
    }

    &__page-number {
      @include font(18,28);
      font-family: "Inter";
      @include mq(sp) {
        @include font(16,28);
      }
    }

    &__title {
      @include font(18,32);

      @include mq(sp) {
        @include font(16,28);
      }
    }

    &__category-item.is-open &__item {
      display: block;
    }

    &__category-item.is-open &__category-heading {
      &::after {
        transition: 0.3s;
        transform: translateY(-50%);
      }
    }
  }
}