@use "../../import" as *;

.p-modal {
  @include innerWidth(950);
  min-height: 395px;
  box-shadow: 2px 4px 16px 0px rgba(0, 0, 0, 0.16);
  border-radius: 14px;
  background-color: $colorWhite;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mq(sp) {
    @include innerWidth(335);
    min-height: 220px;
  }

  &.is-correct {
    box-shadow: 2px 4px 16px 0px rgba(242, 170, 45, 0.57);
  }

  &.is-incorrect {
    box-shadow: 2px 4px 16px 0px rgba(151, 187, 255, 0.57);
  }

  &__inner {
    padding: 124px 124px 130px;
    @include mq(sp) {
      width: 100%;
      height: 100%;
      padding: 36px 29px;
    }
  }

  &__inner-narrow {
    padding: 69px 69px 68px 69px;
    @include mq(sp) {
      width: 100%;
      height: 100%;
      padding: 32px 20px 20px;
    }
  }

  &__text {
    @include font(18,32);
    font-weight: bold;
    color: $colorFont;
    margin-bottom: 39px;
    @include mq(sp) {
      padding-right: 30px;
      padding-left: 30px;
      margin-bottom: 30px;
    }
  }

  &__btn-wrapper {
    display: flex;

    .c-btn {
      margin-right: 20px;
      @include mq(sp) {
        margin-right: 16px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__result-text {
    @include font(60,80);
    font-weight: bold;
    text-align: center;
    @include mq(sp) {
      @include font(42,56);
    }
  }

  &__correct {
    margin-top: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mq(sp) {
      margin-top: 20px;
      padding-top: 4px;
      padding-bottom: 8px;
    }
  }

  &__correct-text {
    @include font(32,48);
    font-weight: bold;
    color: $colorFont;
    margin-right: 20px;
    @include mq(sp) {
      @include font(18,32);
      margin-right: 5px;
    }
  }

  &__correct-icon {
    width: 40px;
    height: 40px;
    display: inline-block;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    @include mq(sp) {
      width: 26px;
    height: 26px;
    }

    &.display-correct {
      background-image: url("../img/icon_answer-correct.svg");
    }

    &.display-incorrect {
      background-image: url("../img/icon_answer-incorrect.svg");
    }
  }

  &__reference {
    margin-top: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mq(sp) {
      margin-top: 23px;
    }
  }

  &__reference-text {
    @include font(18,32);
    font-weight: bold;
    color: $colorFont;
    @include mq(sp) {
      @include font(16,20);
    }
  }

  &__reference-icon {
    margin-right: 7px;
    width: 23.5px;
    height: 20px;
    display: inline-block;
    background-image: url("../img/icon_book.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    @include mq(sp) {
      width: 18.5px;
      height: 15.5px;
    }
  }

  &__foot {
    @include innerWidth(950);
    display: flex;
    justify-content: center;
    margin-top: 30px;
    @include mq(sp) {
      margin-top: 25px;
    }
  }

  &.is-correct &__result-text  {
    color: #FF5B5B;
  }

  &.is-incorrect &__result-text  {
    color: #4B89FE;
  }
  
  &-preview {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &-preview__img-area {
    margin: 0 auto;
    width: fit-content;
    @include mq(sp) {
      width: 100%;
      padding: 0 10px;
    }
  }

  &-preview__img {
    height: auto;
  }
}


.is-open--answer .l-modal {
  animation: modalOpenAnime 0.8s ease;

  @keyframes modalOpenAnime {
    0% { top : -50% }
    100% { top : 50%  }
  }
}