@use "../../import" as *;

.p-top {
  &__img-area {
    max-width:  567px;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }

  &__img {
    width: 100%;
    height: auto;
    object-fit: contain;
    @include mq(sp) {
      padding-right: 11px;
    }
  }

  &__body {
    margin-top: 60px;

    @include mq(sp) {
      margin-top: 44px;
      padding: 0 20px;
    }
  }

  &__heading {
    display: flex;
    justify-content: center;
  }

  &__btns {
    margin-top: 53px;

    @include mq(sp) {
      margin-top: 30px;
    }
  }

  &__text-area {
    @include innerWidth(455);
    margin-top: 24px;

    @include mq(sp) {
      margin-top: 10px;
      max-width: 326px;
      width: 100%;
    }
  }

  &__text {
    @include font(18,32);
    color: $colorFont;
    @include mq(sp) {
      @include font(16,28);
    }
  }
}