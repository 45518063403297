@use "sass:math";

@mixin innerWidth($innerWidth) {
  max-width: ($innerWidth) + px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@mixin font($fontSize,$lineHeight) {
  font-size: math.div($fontSize, 16) * 1rem;
  line-height: calc($lineHeight / $fontSize);
}

$breakpoint: (
  sp: 'screen and (max-width: 949px)',
);

@mixin mq($bp) {
  @media #{map-get($breakpoint, $bp)} {
    @content;
  }
}