@use "../../import" as *;

.p-result {
  &__mv {
    height: 292px;
    position: relative;
    @include mq(sp) {
      height: 216px;
    }
  }

  &__mv-main {
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: flex;
    justify-content: center;
  }

  &__comment {
    @include innerWidth(564);
    height: 115px;
    background-image: url("../img/icon_roll.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mq(sp) {
      @include innerWidth(294);
      height: 60px;
    }

    &::before {
      display: block;
      @include font(24,40);
      font-weight: bold;
      color: $colorFont;
      position: relative;
      top: 6px;
      left: -15px;
      @include mq(sp) {
        @include font(12,20);
        top: 2px;
        left: -7.5px;
      }
    }
  }

  &__body {
    margin-top: 34px;
    @include mq(sp) {
      margin-top: 20px;
    }
  }

  &__heading {
    @include mq(sp) {
      @include innerWidth(335);
    }
  }

  &__heading-number {
    font-family: Inter;
    padding-left: 0.25rem;
  }

  &__heading-category {
    padding: 4px 60px;
    width: fit-content;
    @include font(20,32);
    color: $colorHover;
    border-radius: 21px 21px 0px 0px;
    background: $colorMain;
    text-align: center;
    @include mq(sp) {
      @include font(12,20);
      padding: 1px 27px 0;
      border-radius: 10.5px 10.5px 0px 0px;
    }
  }

  &__heading-title {
    padding: 10px;
    @include font(24,40);
    font-weight: bold;
    border-radius: 0px 8px 8px 8px;
    background: rgba(255, 156, 64, 0.30);
    text-align: center;
    @include mq(sp) {
      @include font(18,32);
      padding: 6px 10px;
    }
  }

  &__heading-part {
    @include font(24,32);
    font-weight: 500;
    margin-left: 1em;
    @include mq(sp) {
      @include font(18,32);
    }
  }

  &__grade {
    margin-top: 24px;
    width: fit-content;
    margin: 0 auto;
    @include mq(sp) {
      margin-top: 10px;
    }
  }

  &__grade-area {
    display: flex;
    align-items: center;
  }

  &__grade-now {
    @include font(96,73);
    font-weight: bold;
    font-family: 'Lexend';
    color: $colorMain;
    text-align: center;
    margin-right: 20px;
    @include mq(sp) {
      @include font(64,73);
      margin-right: 18px;
    }
  }

  &__grade-slash {
    width: 6px;
    height: 39px;
    background-color: $colorFont;
    border-radius: 9px;
    margin-right: 20px;
    transform: rotate(30deg);
    @include mq(sp) {
      width: 5px;
      height: 22px;
      margin-right: 18px;
    }
  }

  &__grade-group {
    display: flex;
    align-items: center;
    margin-top: 12px;
    @include mq(sp) {
      margin-top: 20px;
    }
  }

  &__grade-all {
    @include font(64,73);
    font-weight: bold;
    font-family: 'Lexend';
    color: $colorFont;
    margin-right: 8px;
    @include mq(sp) {
      @include font(36,73);
      margin-right: 6px;
    }
  }

  &__grade-text {
    @include font(32,48);
    font-weight: bold;
    color: $colorFont;
    position: relative;
    top: 4px;
    @include mq(sp) {
      @include font(20,32);
    }
  }

  &__description {
    margin-top: 24px;
    padding: 0 25px;
    @include font(18,32);
    color: $colorFont;
    @include mq(sp) {
      margin-top: 16px;
      margin-bottom: 30px;
      padding: 0;
      @include font(18,32);
      @include innerWidth(335);
    }
  }

  &__btns {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include mq(sp) {
      margin-top: 1px;
    }

    .c-btn__icon {
      margin-top: 24px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &.result-low {
    .p-result__mv-main {
      top: 12px;
      left: 50%;
      transform: translateX(-50%);
      max-width: 950px;
      width: 100%;
      height: 285px;
      @include mq(sp) {
        top: 0;
        max-width: 374px;
        height: 217px;
      }
    }

    .p-result__comment::before {
      content: "もう一度本文と図表を学習しましょう";
    }

    .p-result__comment.category::before {
      content: "もう一度本文と図表を学習し，まずは\A「一問一答問題でチェック」を解きましょう。";
      width: 100%;
      text-align: center;
      white-space: pre;
      @include mq(sp) {
        max-width: 252px;
        width: 100%;
      }
    }
  }
  
  &.result-middle {
    .p-result__mv-main {
      left: 50%;
      transform: translateX(-50%);
      max-width: 950px;
      width: 100%;
      height: 331px;
      @include mq(sp) {
        top: 0;
        max-width: 257px;
        height: 216px;
      }
    }

    .p-result__comment::before {
      content: "もう一息、がんばろう";
    }
  }
  
  &.result-high {
    .p-result__mv-main {
      top: 18px;
      left: 50%;
      transform: translateX(-50%);
      max-width: 950px;
      width: 100%;
      height: 260px;
      @include mq(sp) {
        top: 12px;
        max-width: 302px;
        height: 176px;
      }
    }


    .p-result__comment::before {
      content: "この分野の記憶と理解は十分です！";
    }
  }
}

svg .head {
  position: absolute;
  top: 0;
  left: 10px;
  animation: headDrop 2s ease-in-out infinite alternate;
}

svg .hand {
  position: absolute;
  top: 0;
  left: 0;
  animation: handUp 2s ease-in-out infinite alternate;
}

svg .arm {
  position: absolute;
  top: 5px;
  left: 0;
  animation: armUp 2s ease-in-out infinite alternate;
}

@keyframes headDrop {
  0%, 100% {
      transform: translate(0,0);
  }
  50% {
      transform: translate(5px,5px);
  }
}

@keyframes handUp {
  0%, 100% {
      transform: translateY(3px);
  }
  50% {
      transform: translateY(-2px);
  }
}

@keyframes armUp {
  0%, 100% {
      transform: translateY(5px);
  }
  50% {
      transform: translateY(-5px);
  }
}
