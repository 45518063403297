@use "../../import" as *;

.c-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  width: 100%;
  height: 70px;
  background-color: $colorWhite;
  border: 2px solid $colorMain;
  border-radius: 16px;
  @include font(24,40);
  font-weight: bold;
  color: $colorFont;
  &:hover {
    background-color: $colorHover;
  }
  @include mq(sp) {
    max-width: 154px;
    width: 100%;
    height: 54px;
    @include font(18,32);
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 460px;
    width: 100%;
    padding: 29px 20px 29px 20px;
    background-color: $colorWhite;
    border: 2px solid $colorMain;
    border-radius: 16px;
    position: relative;
    text-align: center;
    @include mq(sp) {
      padding: 14px;
      height: 76px;
      border-radius: 12px;
      max-width: 335px;
    }

    &:hover {
      background-color: $colorHover;
    }

    &::before {
      content: "";
      display: block;
      width: 66px;
      height: 66px;
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      @include mq(sp) {
        width: 48px;
        height: 48px;
        left: 14px;
      }
    }

    &--again::before {
      background-image: url("../img/icon_again.svg");
    }

    &--another::before {
      background-image: url("../img/icon_another.svg");
    }

    &--correct::before {
      background-image: url("../img/icon_correct.svg");
    }

    &--exit::before {
      background-image: url("../img/icon_exit.svg");
    }

    &--pickup::before {
      background-image: url("../img/icon_pickup.svg");
    }

    &--shuffle::before {
      background-image: url("../img/icon_shuffle.svg");
    }

    &--flag::before {
      background-image: url("../img/icon_flag.svg");
    }
  }

  &__icon-text {
    position: relative;
    @include font(24,44);
    font-weight: bold;
    color: $colorFont;
    max-width: 270px;
    width: 100%;
    margin: 0 auto 0 94px;
    display: inline-block;

    @include mq(sp) {
      @include font(18,32);
      margin-left: 58px;
      max-width: 230px;
    }
  }

  &__answer {
    max-width: 460px;
    width: 100%;
    height: 121px;
    // border: 2.4px solid $colorMain;
    // background-color: $colorWhite;
    border-radius: 24px;
    position: relative;
    @include mq(sp) {
      max-width: 160px;
      height: 100px;
    }
    
    input {
      width: 100%;
      height: 100%;
      cursor: pointer;
      position: relative;
      z-index: 1;
      border: 2.4px solid $colorMain;
      background-color: $colorWhite;
      border-radius: 24px;


      &:hover {
        background-color: $colorHover;
      }

      &:checked {
        background-color: $colorMain;
      }
      
      &::before {
        content: "";
        display: block;
        width: 61px;
        height: 61px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        @include mq(sp) {
          width: 50px;
          height: 50px;
        }
      }
    }
  }

  &__answer--correct {
    input {
      &::before {
        mask-image: url("../img/icon_big_correct.svg");
        mask-size: contain;
        background-color: $colorMain;
      }
  
      &:checked::before  {
        mask-image: url("../img/icon_big_correct.svg");
        mask-size: contain;
        background-color: $colorWhite;
      }
    }
  }

  &__answer--incorrect {
    input {
      &::before {
        mask-image: url("../img/icon_big_incorrect.svg");
        mask-size: contain;
        background-color: $colorMain;
      }
  
      &:checked::before {
        mask-image: url("../img/icon_big_incorrect.svg");
        mask-size: contain;
        background-color: $colorWhite;
      }
    }
  }
}