@use "../../import" as *;

.p-bar {
  @include innerWidth(950);

  &__top {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__text-area {
    display: flex;
    align-items: center;
    margin: 0 auto;
  }

  &__text-now {
    @include font(32,32);
    font-weight: bold;
    color: $colorMain;
    font-family: 'Lexend';
    margin-right: 16px;
    @include mq(sp) {
      margin-right: 10px;
    }
  }

  &__text-group {
    margin-top: 12px;
    display: flex;
    align-items: center;
  }

  &__text-slash {
    width: 4px;
    height: 18px;
    border-radius: 8px;
    background-color: #4C4D4D;
    transform: rotate(30deg);
    margin-right: 12px;
    @include mq(sp) {
      width: 2px;
      height: 10px;
      border-radius: 3px;
      margin-right: 5px;
    }
  }

  &__text-all {
    @include font(20,32);
    font-weight: bold;
    color: $colorFont;
    font-family: 'Lexend';
    margin-right: 7px;

    @include mq(sp) {
      @include font(14,32);
    }
  }

  &__text {
    @include font(18,32);
    font-weight: bold;
    color: $colorFont;
  }

  &__body {
    margin-top: 2px;
  }

  &__progress {
    width: 100%;
    height: 24px;
    position: relative;
  }

  &__all {
    width: 100%;
    height: 24px;
    border-radius: 16px;
    background-color: rgba(255, 156, 64, .3);
  }

  &__current {
    width: var(--width-current);
    height: 24px;
    border-radius: 16px 0 0 16px;
    background-color: $colorMain;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  &__current.is-full {
    border-radius: 16px;
  }
}